require('./bootstrap');

(function($) {

    /*// Default tooltips
    $('[data-toggle=tooltip]').tooltip({
        container: 'body',
        boundary: 'window'
    });

    // Default popovers
    $('[data-toggle=popover]').popover({
        container: 'body',
        boundary: 'window'
    });*/

    // Select 2
    $('.select2').select2();

    /*
     * Hacky fix for a bug in select2 with jQuery 3.6.0's new nested-focus "protection"
     * see: https://github.com/select2/select2/issues/5993
     * see: https://github.com/jquery/jquery/issues/4382
     *
     * TODO: Recheck with the select2 GH issue and remove once this is fixed on their side
     */
    jQuery(document).on('select2:open', function (e) {
        window.setTimeout(function() {
            jQuery(".select2-container--open .select2-search__field").get(0).focus();
        }, 200);
    });

})(jQuery);
